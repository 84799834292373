import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useFirebase } from "../../../contexts/firebase";

const MEDIA_RECORDER_OPTIONS: MediaRecorderOptions = {
  audioBitsPerSecond: 44100,
};

enum BLOB_TYPE {
  MP3 = "audio/mp3",
  MP4 = "audio/mp4",
}

const useMediaRecorder = ({ recording }: { recording: boolean }) => {
  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder | null>(null);
  const [videoRecorder, setVideoRecorder] = useState<MediaRecorder | null>(null);
  const [audioMediaStream, setAudioMediaStream] = useState<MediaStream | null>(null);
  const [videoMediaStream, setVideoMediaStream] = useState<MediaStream | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const audioChunks = useRef<any[]>([]);
  const videoChunks = useRef<any[]>([]);

  if ((!audioRecorder || !videoRecorder) && navigator.mediaDevices) {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      setAudioMediaStream(stream);
      const mr = new MediaRecorder(stream, MEDIA_RECORDER_OPTIONS);
      setAudioRecorder(mr);

      mr.onstop = e => {
        const blob = new Blob(audioChunks.current, {
          type: BLOB_TYPE.MP3,
        });

        setAudioBlob(blob);
        audioChunks.current = [];
      };

      mr.ondataavailable = e => {
        audioChunks.current.push(e.data);
      };
    });

    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
      setVideoMediaStream(stream);
      const mr = new MediaRecorder(stream, MEDIA_RECORDER_OPTIONS);
      setVideoRecorder(mr);

      mr.onstop = e => {
        const blob = new Blob(videoChunks.current, {
          type: BLOB_TYPE.MP4,
        });

        setVideoBlob(blob);
        videoChunks.current = [];
      };

      mr.ondataavailable = e => {
        videoChunks.current.push(e.data);
      };
    });
  }

  const stopRecording = useCallback(() => {
    if (audioRecorder && videoRecorder && recording === true) {
      if (audioMediaStream) {
        audioMediaStream.getTracks().forEach(track => {
          track.stop();
        });
      }
      if (videoMediaStream) {
        videoMediaStream.getTracks().forEach(track => {
          track.stop();
        });
      }
      audioRecorder.stop();
      videoRecorder.stop();
      setAudioRecorder(null);
      setVideoRecorder(null);
    }
  }, [audioMediaStream, audioRecorder, recording, videoMediaStream, videoRecorder]);

  useEffect(() => {
    if (audioRecorder && videoRecorder && recording === true) {
      setAudioBlob(null);
      setVideoBlob(null);
      audioChunks.current = [];
      videoChunks.current = [];

      audioRecorder.start();
      videoRecorder.start();

      return () => {
        // This stops the recording
        stopRecording();
      };
    }
  }, [audioMediaStream, audioRecorder, recording, stopRecording, videoMediaStream, videoRecorder]);

  return { audioBlob, videoBlob, recorder: audioRecorder, stopRecording } as const;
};

interface RecordScreenProps {
  onAnalyze: (videoUrl: string) => void;
}

const RecordScreen = ({ onAnalyze }: RecordScreenProps) => {
  const [recording, setRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [hasWebcamStarted, setHasWebcamStarted] = useState(false);
  const [firebaseUrl] = useState<string>(() => `interact/${uuidv4()}.mp4`); // the name of the page has changed from 'interact' to 'embed' but this stayed the same to keep the existing file structure
  const firebase = useFirebase();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { audioBlob, videoBlob, recorder, stopRecording } = useMediaRecorder({
    recording,
  });

  useEffect(() => {
    if (audioBlob && firebaseUrl) {
      var videoRef = firebase.storage.ref(firebaseUrl);

      try {
        firebase.storage.uploadBytes(videoRef, audioBlob).then(() => {
          firebase.storage.getDownloadURLForRef(videoRef).then(url => {
            setVideoUrl(url);
          });
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [audioBlob, firebase.storage, firebaseUrl, setVideoUrl]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h5 style={{ display: "inline-block" }}>Respond</h5>
      {!videoBlob?.size &&
        (recorder ? (
          <Webcam
            onLoadedData={() => {
              setHasWebcamStarted(true);
            }}
            style={{ width: "100%" }}
          />
        ) : (
          "Loading"
        ))}
      {!recording && !!videoBlob?.size ? (
        <div>
          <video
            ref={videoRef}
            controls
            height="370px"
            style={{
              border: "5px solid #bfefff",
              display: "block",
              borderRadius: 10,
              backgroundColor: "#bfefff",
              minHeight: 300,
            }}
            src={URL.createObjectURL(videoBlob)}
            autoPlay={false}
          />
        </div>
      ) : null}

      {!recording && videoUrl && (
        <Button
          style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
          block
          color={"info"}
          onClick={() => {
            if (videoUrl) {
              stopRecording();
              onAnalyze(firebaseUrl);
            }
          }}
        >
          Analyze
        </Button>
      )}

      <div style={{ width: "100%" }}>
        <Button
          style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
          block
          color={"info"}
          onClick={() => {
            if (!hasWebcamStarted) {
              // it doesn't seem to have any disabled prop
              return;
            }

            if (!recording) {
              setVideoUrl(null);
            }
            setRecording(prev => !prev);
          }}
        >
          {hasWebcamStarted ? (recording ? "Stop Recording" : "Start Recording") : "Loading…"}
        </Button>
      </div>

      {!recording && (videoUrl || videoBlob) && (
        <div style={{ width: "100%" }}>
          <Button
            style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
            block
            color={"info"}
            onClick={() => {
              videoRef.current?.play();
            }}
          >
            Review
          </Button>
        </div>
      )}
    </div>
  );
};

export default RecordScreen;
