import React, { useEffect, useRef } from "react";

interface VideoScreenProps {
  videoUrl: string;
  autoPlay?: boolean;
  paused?: boolean;
  onVideoEndOrPaused?: () => void;
  onVideoPlay?: () => void;
  videoHeight?: string; // 600px as default works well on all the screens given the size of the widget box
}

const VideoScreen = ({
  videoUrl,
  videoHeight = "600px",
  autoPlay,
  paused,
  onVideoEndOrPaused,
  onVideoPlay,
}: VideoScreenProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (paused === true) {
      videoRef.current?.pause();
    } else if (paused === false) {
      videoRef.current?.play();
    }
  }, [paused]);

  return (
    <video
      key={videoUrl}
      onPause={() => {
        onVideoEndOrPaused?.();
      }}
      onEnded={() => {
        onVideoEndOrPaused?.();
      }}
      onPlay={() => {
        onVideoPlay?.();
      }}
      ref={videoRef}
      controls
      height={videoHeight}
      style={{
        display: "block",
        backgroundColor: "#bfefff",
        minHeight: 200,
      }}
      src={videoUrl}
      autoPlay={autoPlay}
    ></video>
  );
};

export default VideoScreen;
