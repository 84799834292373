import React, { useCallback, useEffect, useMemo, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import AnalysisScreen from "../../../../components/Interact/AnalysisScreen";
import RecordScreen from "../../../../components/Interact/RecordScreen";
import VideoScreen from "../../../../components/Interact/VideoScreen";
import { INTERACT_USER_EMAIL, INTERACT_USER_PASSWORD } from "../../../../constants/env";
import {
  CREDENTIALS_EMBED_URL,
  CREDENTIALS_INTERACT_URL,
  LOGIN_URL,
} from "../../../../constants/urls";
import { useAuth } from "../../../../contexts/authUser";
import { useFirebase } from "../../../../contexts/firebase";
import { ifxApiClient } from "../../../../helpers/api";
import { EMBED_STAGE, EmbedButton } from "../common";

interface EmbedContentPageProps {
  interactionVideoUrl: string;
  expertVideoUrl: string;
  sceneId: string;
  storyId: string;
}

interface EmbedPageProps {
  userId: string;
  setPreventSignIn: () => void;
  isEmbedded?: boolean;
}

const EmbedContentPage = ({
  userId,
  setPreventSignIn,
  interactionVideoUrl,
  expertVideoUrl,
  storyId,
  sceneId,
  isEmbedded = false,
}: EmbedPageProps & EmbedContentPageProps) => {
  const [embedStage, setEmbedStage] = useState(EMBED_STAGE.INTERACTION_VIDEO);
  const [firebaseVideoUrl, setFirebaseVideoUrl] = useState<string | undefined>();
  const [analysisResponse, setAnalysisResponse] = useState<any>(null);
  const history = useHistory();
  const [isVideoPaused, setIsVideoPaused] = useState<boolean | undefined>();
  const firebase = useFirebase();

  useEffect(() => {
    // So the user gives webcam permission from the beginning, so when we reach the recording screen, it's already approved.
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        stream.getTracks().forEach(x => x.stop());
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const isLoading =
    embedStage === EMBED_STAGE.AUTHENTICATION || !interactionVideoUrl || !expertVideoUrl;

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "scroll",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "5px solid #bfefff",
        borderRadius: 10,
        padding: "20px",
      }}
    >
      {isLoading && <h5>Loading&hellip;</h5>}

      {embedStage === EMBED_STAGE.INTERACTION_VIDEO && interactionVideoUrl && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VideoScreen
            videoUrl={interactionVideoUrl}
            autoPlay
            paused={isVideoPaused}
            onVideoEndOrPaused={() => {
              setIsVideoPaused(true);
            }}
            onVideoPlay={() => {
              setIsVideoPaused(false);
            }}
          />

          <EmbedButton
            onClick={() => {
              setIsVideoPaused(prev => !prev);
            }}
          >
            {isVideoPaused ? "Listen" : "Pause"}
          </EmbedButton>

          <EmbedButton
            onClick={() => {
              setEmbedStage(EMBED_STAGE.RESPONSE_VIDEO_RECORDING);
            }}
          >
            Respond
          </EmbedButton>
        </div>
      )}
      {embedStage === EMBED_STAGE.EXPERT_VIDEO && expertVideoUrl && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5 style={{ display: "inline-block" }}>Expert video</h5>
          <VideoScreen videoUrl={expertVideoUrl} />
          <EmbedButton
            onClick={() => {
              setAnalysisResponse(null);
              setEmbedStage(EMBED_STAGE.INTERACTION_VIDEO);
            }}
          >
            Try again
          </EmbedButton>

          <EmbedButton
            onClick={() => {
              setEmbedStage(EMBED_STAGE.ANALYSIS);
            }}
          >
            Go back to feedback
          </EmbedButton>
        </div>
      )}

      {embedStage === EMBED_STAGE.RESPONSE_VIDEO_RECORDING && (
        <RecordScreen
          onAnalyze={videoUrl => {
            setFirebaseVideoUrl(videoUrl);
            setEmbedStage(EMBED_STAGE.ANALYSIS);
          }}
        />
      )}

      {embedStage === EMBED_STAGE.ANALYSIS && (
        <AnalysisScreen
          storyId={storyId}
          sceneId={sceneId}
          userId={userId}
          videoUrl={firebaseVideoUrl}
          onStageChange={async stage => {
            if (stage) {
              setEmbedStage(stage);
            } else {
              setPreventSignIn();
              await firebase.doSignOut();
              history.push(LOGIN_URL);
            }
          }}
          analysisResponse={analysisResponse}
          onAnalysisResponse={setAnalysisResponse}
          isEmbedded={isEmbedded}
        />
      )}
    </div>
  );
};

const EmbedContentPageWrapper = (props: EmbedPageProps) => {
  const [interactionVideoUrl, setInteractionVideoUrl] = useState<null | string>(null);
  const [expertVideoUrl, setExpertVideoUrl] = useState<null | string>(null);
  const [storyId, setStoryId] = useState<null | string>(null);
  const [sceneId, setSceneId] = useState<null | string>(null);
  const [isEmbedded, setIsEmbedded] = useState(false);

  const firebase = useFirebase();
  useEffect(() => {
    const embedMatch = matchPath<{ storyId: string; sceneId: string }>(window.location.pathname, {
      path: CREDENTIALS_EMBED_URL,
      exact: true,
      strict: false,
    });

    const interactMatch = matchPath<{ storyId: string; sceneId: string }>(
      window.location.pathname,
      {
        path: CREDENTIALS_INTERACT_URL,
        exact: true,
        strict: false,
      }
    );

    const match = embedMatch || interactMatch;

    if (!!embedMatch) {
      setIsEmbedded(true);
    }

    setStoryId(match?.params.storyId || null);
    setSceneId(match?.params.sceneId || null);
  }, []);

  useEffect(() => {
    if (sceneId) {
      ifxApiClient
        // TODO: .get<ResponseShape>
        .get(`scenes/${sceneId}`)
        .then(sceneResponse => {
          const data = sceneResponse.data as Record<string, unknown>;

          console.log(sceneId, data);

          const videos = data.videos as Record<string, string>;
          const interactionFileName =
            videos.interactionVideoName + "." + videos.interactionVideoType;
          firebase.storage.getDownloadURLForPath(interactionFileName).then(url => {
            setInteractionVideoUrl(url);
          });

          const expertFileName = videos.professionalVideoName + "." + videos.professionalVideoType;
          firebase.storage.getDownloadURLForPath(expertFileName).then(url => {
            setExpertVideoUrl(url);
          });
        })
        .catch(err => console.error(err));
    }
  }, [firebase.storage, sceneId]);

  if (interactionVideoUrl && expertVideoUrl && storyId && sceneId) {
    return (
      <EmbedContentPage
        {...props}
        interactionVideoUrl={interactionVideoUrl}
        expertVideoUrl={expertVideoUrl}
        storyId={storyId}
        sceneId={sceneId}
        isEmbedded={isEmbedded}
      />
    );
  }

  return <h5>Loading&hellip;</h5>;
};

const EmbedPageAuthWrapper = () => {
  const auth = useAuth();
  const [preventSignIn, setPreventSignIn] = useState(false);

  useEffect(() => {
    async function asyncLogIn() {
      await auth.signInWithEmailAndPassword(INTERACT_USER_EMAIL, INTERACT_USER_PASSWORD);
    }

    // TODO: remove when adding the new user role
    if (preventSignIn || !!auth.authUser) {
      // when exiting we call doSignOut, but right after this hook signs the user back in
      return;
    }

    asyncLogIn();
  }, [auth, preventSignIn]);

  const contentBlock = auth.authUser ? (
    <EmbedContentPageWrapper
      userId={auth.authUser.uid}
      setPreventSignIn={() => setPreventSignIn(true)}
    />
  ) : (
    <h5>Loading&hellip;</h5>
  );

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "scroll",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "5px solid #bfefff",
        borderRadius: 10,
        padding: "20px",
      }}
    >
      {contentBlock}
    </div>
  );
};

export default EmbedPageAuthWrapper;
